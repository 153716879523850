"use client";

// imports
import { useState } from "react";
import Link from 'next/link';

// components
import ITSImage from '@/components/ITSImage/ITSImage';

// svgs
import NextIcon from '/public/svg/caret-next.svg';
import CloseIcon from '/public/svg/close.svg';

// styles
import styles from './ConfigurableBadge.module.scss';
interface ConfigurableBadgeProps {
  configurableBadgeShowLogoUrl: string;
  configurableBadgeShowLogoAlt: string;
  configurableBadgeCTAUrl: string;
  configurableBadgeCTAText: string;
  configurableBadgeHeadline: string;
  color?: "teal" | "yellow" | "red";
  size?: "default" | "quarter" | "half";
}
const ConfigurableBadge = (props: ConfigurableBadgeProps) => {
  const [hidden, setHidden] = useState(false);
  const {
    configurableBadgeShowLogoUrl,
    configurableBadgeShowLogoAlt,
    configurableBadgeCTAUrl,
    configurableBadgeCTAText,
    configurableBadgeHeadline,
    color = "teal",
    size = "half"
  } = props;
  const hideBadgeCookieAge = "604800"; // expires in 7 days
  const hideBadge = () => {
    document.cookie = `hideConfigurableBadge=true; path=/; max-age=${hideBadgeCookieAge}`;
    setHidden(true);
  };
  if (hidden) return null;
  let classes = styles.configurable_badge;
  switch (size) {
    case "quarter":
      classes = `${classes} ${styles.quarter}`;
      break;
    case "half":
      classes = `${classes} ${styles.half}`;
      break;
    default:
      break;
  }
  return <div className={`${classes} ${styles[color]}`} data-sentry-component="ConfigurableBadge" data-sentry-source-file="ConfigurableBadge.tsx">
        <button className={styles.configurable_badge__close_button} onClick={hideBadge} aria-label='Close'>
          <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="ConfigurableBadge.tsx" />
        </button>
        <Link className={styles.configurable_badge__show_logo} href={configurableBadgeCTAUrl} data-sentry-element="Link" data-sentry-source-file="ConfigurableBadge.tsx">
          <ITSImage src={configurableBadgeShowLogoUrl} alt={configurableBadgeShowLogoAlt} width={240} data-sentry-element="ITSImage" data-sentry-source-file="ConfigurableBadge.tsx" />
        </Link>
        <span className={styles.configurable_badge__headline}>
          {configurableBadgeHeadline}
        </span>
        <Link className={styles.configurable_badge__cta} href={configurableBadgeCTAUrl} title={configurableBadgeCTAText} data-sentry-element="Link" data-sentry-source-file="ConfigurableBadge.tsx">
          {configurableBadgeCTAText}
          <NextIcon data-sentry-element="NextIcon" data-sentry-source-file="ConfigurableBadge.tsx" />
        </Link>
      </div>;
};
export default ConfigurableBadge;